<template>
<div class="bg">
  <header-block />
  <div class="container container--step">
    <div class="steps__block">
      <h1 class="title title--step">
        ГАЙДБУК "СТИЛЬ ОТ LER_CHEK"
      </h1>
      <div class="steps">
        <div class="step">
          <p class="step__num">
            1
          </p>
          <p class="step__desc">
            Основные направления и сочетания цветов
          </p>
          <p class="step__txt">
            <a href="https://500405.selcdn.ru/lerfit/guide/1.pdf" target="_blank" class="button">Скачать</a>
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            2
          </p>
          <p class="step__desc">
            Принты и детали
          </p>
          <p class="step__txt">
            <a href="https://500405.selcdn.ru/lerfit/guide/2.pdf" target="_blank" class="button">Скачать</a>
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            3
          </p>
          <p class="step__desc">
            Верхняя одежда, джинсы, топы, трикотаж, обувь, сумки, аксессуары
          </p>
          <p class="step__txt">
            <a href="https://500405.selcdn.ru/lerfit/guide/3.pdf" target="_blank" class="button">Скачать</a>
          </p>
        </div>
        <div class="step">
          <p class="step__num">
            4
          </p>
          <p class="step__desc">
            Готовимся к 8 марта. Капсула для мероприятий
          </p>
          <p class="step__txt">
            <a href="https://500405.selcdn.ru/lerfit/guide/4.pdf" target="_blank" class="button">Скачать</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'Home',
};
</script>
